import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'

export default ({ location, data }) => {
  const post = data.nodeWebshopProduct
  const dataImgs = data.nodeWebshopProduct.relationships.field_image_carrousel
  const tags = data.nodeWebshopProduct.relationships.field_tags
  const baseurl = data.site.siteMetadata.siteUrl
  const pathname = location.pathname;
  //const url = baseurl + pathname + '/'
  //const url = pathname + '/'
  const url = '/'

  return (
    <Layout>
    <div id="fh5co-product">
		<div class="container">
			<div class="row">
				<div class="col-md-10 animate-box fadeInUp animated-fast">
					<h1>{ post.title }</h1>
					<div class="align-left">
					{tags.map(item => (<span key={item.name} class="label label-info">{item.name}</span>))}
				</div>
				</div>
				<div class="col-md-6 animate-box fadeInUp animated-fast">
				
				        <div style={{ maxWidth: `400px`, marginBottom: `1.45rem`, width: `100%` }}>  
					          	<Carousel
								  renderCenterLeftControls={({ previousSlide }) => (
								    <button onClick={previousSlide}>PREV</button>
								  )}
								  renderCenterRightControls={({ nextSlide }) => (
								    <button onClick={nextSlide}>NEXT</button>
								  )}
								>
								  {dataImgs.map(item => (
									  <Img key={item.name} fluid={item.localFile.childImageSharp.fluid} />
									  ))}
							    </Carousel>	
				        </div>  
				</div>
				<div class="col-md-5 animate-box fadeInUp animated-fast">
						
			    		<div dangerouslySetInnerHTML={{ __html: post.body.value }}></div>
						<br></br>
						<p>		
												<a
												href="/"
												class="icon"
												className="Product-button snipcart-add-item"
												data-item-id={post.id}
												data-item-price={post.field_price}
												data-item-image={post.relationships.field_image_carrousel.[0].localFile.childImageSharp.fluid.src}
												data-item-name={post.title}
												data-item-url={`/`}
												data-item-custom1-name="CustomerProfitPercentage"
												data-item-custom1-type="hidden"
												data-item-custom1-value={post.field_custprofitpct}
												>
												<i class="icon-shopping-cart"></i> Add to Cart</a>
						</p>
					
				</div>
		    </div>
		</div>
	</div>
      
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    nodeWebshopProduct(id: { eq: $id }) {
      id
      title
      created
      field_price
      field_custprofitpct
      body {
          value
      }
      relationships {
	    field_tags {
            name
          }  
        field_image_carrousel {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
                src
                }
              }
            } 
        }    
      }
    }
    site {
    siteMetadata {
      title
      description
      siteUrl
    }
  }
  }
`




